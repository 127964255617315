import React,{useState} from "react";

export const Gallery = (props) => {
  return (
    <div id="gallery" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>OUR COMPOUND</h2>
        </div>
        <div id="gallery">
          <div className="row ">
            <div className="col-md-6 col-sm-3 gallery">
                <img src="./img/class7.jpg" className="col-md-12" width="100%" />
            </div>
            <div className="col-md-6 col-sm-3 gallery">
                <img src="./img/bed.jpg" className="col-md-12" width="100%" />
                 </div>
            
          </div>
          <div className="row gallery">
            <div className="col-md-4 col-sm-3 gallery">
                <img src="./img/write.jpg" className="team-img col-md-12" width="100%" />
                </div>
                <div className="col-md-4 col-sm-3 gallery">
                    <img src="./img/class.jpg" className="col-md-12" width="100%" />
                </div>
                <div className="col-md-4 col-sm-3 gallery">
                    <img src="./img/class2.jpg" className="col-md-12" width="100%" />
                </div>
          </div>
          <div className="row gallery">
            <div className="col-md-4 col-sm-3 gallery">
                <img src="./img/room.jpg" className="team-img col-md-12" width="100%" />
                </div>
                <div className="col-md-4 col-sm-3 gallery">
                    <img src="./img/class5.jpg" className="col-md-12" width="100%" />
                </div>
                <div className="col-md-4 col-sm-3 gallery">
                    <img src="./img/class6.jpg" className="col-md-12" width="100%" />
                </div>
          </div>
        </div>
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Open House</h2>
        </div>
        <div>
        <div className="row">
            <div className="col-md-6 col-sm-3 gallery">
                <img src="./img/group.jpg" className="col-md-12" width="100%" />
            </div>
            <div className="col-md-6 col-sm-3 gallery">
                <img src="./img/opening.jpg" className="col-md-12" width="100%" />
                 </div>
            
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-3 openHouse">
                <img src="./img/play.jpg" className="col-md-12" width="100%" height="250px" />
            </div>
            <div className="col-md-4 col-sm-3 openHouse">
                <img src="./img/play1.jpg" className="col-md-12" width="100%" height="250px" />
            </div>
            <div className="col-md-4 col-sm-3 openHouse">
                <img src="./img/play2.jpg" className="col-md-12" width="100%" height="250px" />
            </div>
          </div>
          <div className="row ">
            <div className="col-md-4 col-sm-3 openHouse">
                <img src="./img/discusion.jpg" className="col-md-12" width="100%" height="250px" />
                </div>
                <div className="col-md-4 col-sm-3 openHouse">
                    <img src="./img/discussion.jpg" className="col-md-12" width="100%" height="250px" />
                </div>
                <div className="col-md-4 col-sm-3 openHouse">
                    <img src="./img/discussion1.jpg" className="col-md-12" width="100%" height="250px" />
                </div>
          </div>
          <div className="row ">
            <div className="col-md-4 col-sm-3 openHouse">
                <img src="./img/gabi.jpg" className="col-md-12" width="100%" height="250px" />
                </div>
                <div className="col-md-4 col-sm-3 openHouse">
                    <img src="./img/hana_edited.jpg" className="col-md-12" width="100%" height="250px" />
                </div>
                <div className="col-md-4 col-sm-3 openHouse">
                    <img src="./img/openHouse.jpg" className="col-md-12" width="100%" height="250px" />
                </div>
          </div>
        </div>
      </div>
    </div>
  );
};
