import { Button } from "antd";
import React from "react";


export const Register = (props) => {

  const onDownloadCalenderClick = () => {
    // using Java Script method to get PDF file
    fetch('Annual Calendar.pdf').then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'Annual Calendar.pdf';
            alink.click();
        })
    })
}

const onDownloadRFormClick = () => {
  // using Java Script method to get PDF file
  fetch('Registration Form.docx').then(response => {
      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = 'Registration Form.docx';
          alink.click();
      })
  })
}

  return (
    <div id="register" style={{background:"#f6f6f6"}} className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Register</h2>
        </div>
        <div className="row registerRequirment">
        <div  className="col-md-6">
        <ul>
              <li> <h4 className="attachmentTitle">Admission Requirements</h4> </li>
              <li><b>1. Child's most recent school report</b></li>
              <li><b>2. Child's passport/birth certificate</b></li>
              <li><b>3. Child immunization record/current medical certificate</b></li>
              <li><b>4. Completed and signed registration form</b></li>

              <li>
              </li>
            
            </ul>
        </div>
        {/*<div  className="col-md-5">
            <ul>
              <li><h4 className="attachmentTitle">Attachments</h4></li>
              <li>    
                <div className="attachment">
                <label className="button" onClick={onDownloadCalenderClick}><b>Download Calendar</b></label>
                <label className="button" onClick={onDownloadRFormClick}><b>Download Registration Form</b></label>
              </div></li>
          
            </ul>
          
           
  </div>*/}
      </div>
    </div>
    </div>
  );
};
