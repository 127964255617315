import { useState } from "react";
import emailjs from "emailjs-com";
import Iframe from 'react-iframe'
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    emailjs
      .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_USER_ID")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                
                </div>
                <div className='iframe'>
                    <Iframe width="100%" height="50%" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1334.0990536794573!2d38.77217126351192!3d9.021315565546686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b856b6eb28e25%3A0x88fa30677d4fd38d!2sBusy%20Bees%20Addis!5e0!3m2!1sen!2set!4v1690442299034!5m2!1sen!2set" frameborder="0" allowfullscreen/>
                  </div>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <hr />
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                <b>{props.data ? props.data.address : "loading"}</b>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                <b>{props.data ? props.data.phone : "loading"}</b>
              </p>
            </div>
            <div className="contact-item hi">
              
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : "/"}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div> 
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2023 Busy Bees Addis. All rights reserved.
            <a href="https://busybeesaddis.com">
              
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
